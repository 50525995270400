import { motion } from "framer-motion";
import * as cx from "classnames/bind";
import { Button, Input, Loader, Select } from "components";
import InputContainer from "components/atoms/Input/InputContainer";
import { useRecipeLists, useSession } from "hooks";
import Image from "next/legacy/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import s from "styles/Gallery.module.scss";
import { imageUrl, uniqObjArray, translate } from "utils";
import { useRouter } from "next/router";

const CollectionCard = ({ collections, collection, className, ...props }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { data: session, status } = useSession();
  const regex = /(<([^>]+)>)/gi;

  return (
    <motion.div
      className={cx("rowItem", className, s.CollectionFeedItem, s.GalleryItem)}
      key={collection.id}
      {...props}
    >
      <Link href={"/cocktails/collections/" + collection.slug} itemProp="url">
        <div
          className={cx(
            "GalleryImageWrapper Rad ofh p0",
            s.GalleryImageWrapper,
          )}
        >
          {/*
          <Image
            src="/img/classics.jpg"
            layout="intrinsic"
            height={900}
            width={700}
            alt={t("Classics")}
          />
          */}
          <Image
            src={imageUrl(collection.image_id || "")}
            layout="responsive"
            width={350}
            height={450}
            objectFit="cover"
            itemProp="image"
            alt={collection.name}
          />
        </div>
        <header>
          <h3 className="Heading__sub Bold mb0">
            {translate(collection, "name", locale)}
          </h3>
          <p>
            {collection.cocktail_categories_aggregate.aggregate.count}&nbsp;
            {t("recipes")}
          </p>
        </header>
      </Link>
    </motion.div>
  );
};

export default CollectionCard;
